<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab>Dados</v-tab>

      <v-tab>Equipe</v-tab>

      <v-tab>Serviços</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-title class="pa-4 font-weight-bold accent">
            <v-icon left color="primary">mdi-domain</v-icon> Dados Empresa
            <v-spacer></v-spacer>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text style="height: calc(100vh - 230px); overflow:auto;">
            <v-row>
              <!-- Razão Social -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.razao_social"
                  label="Razao Social"
                  dense
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <!-- Nome Fantasia -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.nome_fantasia"
                  label="Nome Fantasia"
                  dense
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <!-- CPF -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.cpf"
                  label="CPF"
                  dense
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <!-- RG -->
              <v-col cols="12" md="3">
                <v-text-field
                  readonly
                  v-model="cliente.rg"
                  label="RG"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <!-- Ramo Negocio -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.ramo_negocio"
                  label="Ramo Negocio"
                  dense
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <!-- E-mail -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.email"
                  label="E-mail"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Telefone -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.telefone"
                  label="Telefone"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Celular -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.celular"
                  label="Celular"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Pag.Web -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.page"
                  label="Pag.Web"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Whats -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.whatsapp"
                  label="Whats"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Telegram -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.telegram"
                  label="Telegram"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Facebook -->
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="cliente.facebook"
                  label="Facebook"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Instagram -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="cliente.instagram"
                  label="Instagram"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- YouTube -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="cliente.youtube"
                  label="YouTube"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <!-- Linkedim -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="cliente.linkedim"
                  label="Linkedim"
                  dense
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="cliente.descricao_servicos"
                  label="Descrição dos Serviços"
                  outlined
                  :rows="4"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <Equipe :cliente_id="cliente.id" />
      </v-tab-item>
      <v-tab-item>
        <Servicos :cliente_id="cliente.id" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { fetchEmpresa } from "@/api/cliente/cliente_empresa.js";
import { mapState } from "vuex";

export default {
  name: "Dados",

  data() {
    return {
      cliente: {},
      tab: 0,
    };
  },

  components: {
    Equipe: () => import("./components/Equipe.vue"),
    Servicos: () => import("./components/Servicos.vue"),
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
  },

  methods: {
    getCliente() {
      this.loading = true;
      return fetchEmpresa(this.user_id)
        .then((response) => {
          this.cliente = response;
        })
        .finally(() => (this.loading = false));
    },
  },

  async mounted() {
    await this.getCliente();
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  height: 390px;
  overflow-y: auto;
}
</style>
